var mainObject = {
    functions: {
         slick: function() {
            $('.slider-for img').click(function(){
                $('#modalMine').css('visibility', 'visible')
            })
            $('.close-modal').click(function(){
                $('#modalMine').css('visibility', 'hidden')
            })
	       
            $('.slider-for').slick({
              	slidesToShow: 1,
              	slidesToScroll: 1,
              	arrows: true,
              	fade: true,
              	initialSlide: 0,
              	centerMode: true,
              	asNavFor: '.slider-nav, .slider-modal'
	        });
	        
	        $('.slider-modal').slick({
              	slidesToShow: 1,
              	slidesToScroll: 1,
              	arrows: true,
              	fade: true,
              	initialSlide: 0,
              	asNavFor: '.slider-for, .slider-nav',
              	swipe: false
	        });
	        
            $('.slider-nav').slick({
        	  slidesToShow: 7,
        	  slidesToScroll: 1,
        	  asNavFor: '.slider-for, .slider-modal',
        	  dots: false,
        	  initialSlide: 0,
        	  arrows: false,	 	
        	  focusOnSelect: true,
        	  responsive: [
        	    {	 
        	      breakpoint: 768,
        	      settings: {
        	        slidesToShow: 4,
        	        slidesToScroll: 1
        	      }
        	    },
        	    {
        	      breakpoint: 480,
        	      settings: {
        	        slidesToShow: 2,
        	        slidesToScroll: 1
        	      }
        	    }]
        	});
        },
        indexLoader: function() {
            $("#header-video").on("loadstart", function () {
                 //console.log('começou a carregar');
                 $('#video-loader-modal').fadeIn();
                 $('#video-loader-modal').css('transform', 'translateX(0)');
            });
            $("#header-video").on("loadeddata", function () {
                 //console.log('pronto para rodar');
                 $('#video-loader-modal').css('transform','translateX(-100%)').delay(1500).fadeOut('slow');
            });
        },
        generic_slick: function (cls, toshow, navfor, responsive) {
            $(cls).slick({
        	  slidesToShow: toshow,
        	  slidesToScroll: 1,
        	  dots: false,
        	  initialSlide: 0,
        	  arrows: true,	 	
        	  focusOnSelect: true,
        	  asNavFor: navfor ? navfor : null,
        	  prevArrow:"<div class='prev slick-prev'></div>",
              nextArrow:"<div class='next slick-next'></div>",
        	  responsive: responsive ? [
        	    {	 
        	      breakpoint: 992,
        	      settings: {
        	        slidesToShow: 4,
        	        slidesToScroll: 1
        	      }
        	    },
        	    {	 
        	      breakpoint: 768,
        	      settings: {
        	        slidesToShow: 2,
        	        slidesToScroll: 1
        	      }
        	    },
        	    {
        	      breakpoint: 480,
        	      settings: {
        	        slidesToShow: 1,
        	        slidesToScroll: 1
        	      }
        	    }] : null
        	});
        },
        contact_label_hover: function() {
            $("[type=text], [type=email], [type=tel], textarea").focus(function() {
                 $(this).prev().css('color', '#ff9600')
            }).focusout(function() {
                $(this).prev().css('color', '#484853')
            })
        },
        mapaOptions: function(lat, long){
        	return {
        	  center: new google.maps.LatLng(lat, long),
        	  zoom: 15,
              mapTypeId: google.maps.MapTypeId.ROADMAP
        	}
        },
        map_contact: function () {
           var map = new google.maps.Map(document.getElementById("mapContact"), this.mapaOptions(-23.956968, -46.330464));
           var marker = new google.maps.Marker({
                position: new google.maps.LatLng(-23.956968, -46.330464),
                animation: google.maps.Animation.DROP,
                title:"Onde estamos"
           });
           marker.setMap(map)
           return map;
        },
        events: {
            closeModal: function(target) {
                $(target).click(function(){
                    $('#modalMine').css('visibility', 'visible')
                })
                $('.close-modal').click(function(){
                    $('#modalMine').css('visibility', 'hidden')
                })
			},
        },
        status: function() {
            console.log('OKAY')
        }
    }
}